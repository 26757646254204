* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 20px;
}

body {
  margin: 0;
  font-family: "Source Sans Pro",
    sans-serif;
  font-size: 0.8rem;
}

.fullwidth {
  width: 100%;
}

.fg3 {
  flex-grow: 3 !important;
}
.fg2 {
  flex-grow: 2 !important;
}

.space-between {
  justify-content: space-between;
}

.padding {
  padding: 20px;
}

.padding2 {
  padding: 20px;
  overflow: auto;
}


.padding-tb {
  padding: 10px 0;
}

.btn {
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  font: inherit;
  height: 34px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  &.primary {
    background-color: #106FCE;
    color: #fff;
  }
}
.disabled{
  opacity: 0.65;
  cursor: default;
  &.btn {
    background-color: #888;
    color: #fff;
  }
}

.flex {
  display: flex;
}

input, select {
  font: inherit;
  font-size: 0.7rem;
}

.mt-4 {
  margin-top: 16px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-4 {
  margin-right: 16px;
}

.ml-17 {
  margin-left: 17px;
}

.ml-30 {
  margin-left: 30px;
}

.mb-4 {
  margin-bottom: 16px;
}

button {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100vh;
}

#logout {
  text-transform: capitalize;
  background-color: transparent;
  border: 0;
  font: inherit;
  font-size: 0.7rem;
  margin-left: 20px;
  &:hover {
    text-decoration: underline;
  }
}

.flex-grow {
  flex-grow: 1;
}

#logo {
  height: 40px;
  margin-right: 20px;
}

#app-wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  align-items: stretch;
}

#logo {
  display: block;
  padding: 10px;
  height: 60px;
  border-bottom: 1px solid #dddddd;
  width: 100%;
}

#logo-img {
  height: 100%;
}

#sidebar {
  width: 210px;
}

#sidebar-nav {
  padding: 20px 12px 12px;
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .material-icons {
    font-size: 20px;
    margin-right: 6px;
  }
}

.sidebar-nav-item-link {
  align-items: center;
  border-radius: 4px;
  color: #4b5060;
  display: flex;
  margin-bottom: 8px;
  padding: 10px 12px;
  text-decoration: none;
  text-transform: capitalize;
  &.active {
    background-color: #f8f9fc;
    color: #295efa;
  }
}

#right-section {
  width: 10px;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
}

#header {
  height: 60px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 1rem;
}

#plan-page {
  flex-grow: 1;
  flex-shrink: 1;
  height: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .react-grid-item {
    background-color: #ffffff;
  }
}

#input-section {
  padding: 7px 20px 20px;
  overflow: auto;
  height: 100%;
  position: relative;
  width: 100%;
}

#output-section {
  padding: 7px 20px 20px;
  overflow: auto;
  height: 100%;
  position: relative;
  width: 100%;
  .input-label {
    white-space: nowrap;
    overflow: hidden;
    margin-right: 16px;
  }
}

.cargo-info-table-item {
  line-height: 1.8;
}


#viz-section {
  padding: 7px 20px 20px;
  position: relative;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
}

#viz-wrapper {
  border-radius: 4px;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  height: 10px;
  background-color: #ffffff;
}

.section-heading {
  text-transform: uppercase;
  margin: 0;
  font-size: 0.7rem;
}

.input-margin-section {
  margin-bottom: 32px;
}

.input-row {
  padding: 6px 0;
  display: flex;
  align-items: center;
}

.input-row-text {
  width: 205px;
  flex-shrink: 0;
  white-space: nowrap;
  &.small {
    width: 160px;
  }
}

@media screen and (min-width: 2000px) {
  .input-row-text {
    width: 250px;
    &.small {
      width: 220px;
    }
  }
}

.input-wrapper {
  width: 200px;
  flex-grow: 1;
  position: relative;
  &.wide {
    flex-grow: 2;
  }
}

.input-label {
  font-size: 0.6rem;
}

.input-field {
  width: calc(100% - 30px);
  border: 0;
  border-radius: 4px;
  background-color: #EDEDED;
  height: 27px;
  padding-left: 4px;
  &.fw {
    width: 100%;
  }
  &.lang {
    width: 100px;
  }
  &[disabled] {
    color: #222222;
    background: transparent;
    border: 2px solid #EDEDED;
    cursor: not-allowed;
  }

  &.bgbl {
    background-color: #222222;
    color: #ffffff;
  }
  &.bggr {
    background-color: #7F7F7F;
    color: #ffffff;
  }
  &.bgs {
    background-color: #deeaf6;
  }
  &.bgw {
    background-color: #ffffff;
  }
  &.bgb {
    background-color: #2e75b5;
    color: #ffffff;
  }
  &.bgw {
    background-color: #ffffff;
  }
  &.bgg {
    background-color: #d6dce4;
  }

  &.bglo {
    background-color: #FBE4D4;
  }
  &.bgo {
    background-color: #EC7D31;
  }


  &.bob {
    box-shadow: inset 0 0 0 1px #000000;
  }
  &.bor {
    box-shadow: inset 0 0 0 1px #ff0000;
  }

  &.cb {
    color: #0070c0;
  }
  &.cw {
    color: #ffffff;
  }
}

.input-unit {
  position: absolute;
  font-size: 0.7rem;
  right: 16px;
  bottom: 0;
  &.rent {
    right: -34px;
  }
  &.kg {
    right: 14px;
  }
  &.tm2 {
    left: calc(100% - 27px);
    right: initial;
  }
}

/***********************************
          Checkbox
 **********************************/
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkbox-mark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkbox-mark {
  background-color: #106FCE;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkbox-mark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkbox-mark:after {
  left: 7px;
  top: 4px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btn-group {
  display: inline-flex;
  border: 2px solid #106FCE;
  border-radius: 4px;
}

.toggle-btn {
  font: inherit;
  padding: 5px 20px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  &.selected {
    background-color: #106FCE;
    color: #ffffff;
    cursor: initial;
  }
}

#nopermission {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  line-height: 1.6;
}

.home-plan-item {
  color: inherit;
  text-decoration: none;
  background-color: #fff;
  margin: 0 20px 20px 0;
  padding: 20px 40px 20px 20px;
  border-radius: 4px;
  &:hover {
    color: #106FCE;
  }
  h3 {
    margin: 0;
  }
}

.plantsim-plan-item {
  color: inherit;
  text-decoration: none;
  background-color: #fff;
  margin: 0 20px 20px 0;
  padding: 20px 40px 20px 20px;
  border-radius: 4px;
  &:hover {
    color: #106FCE;
  }
  h3 {
    margin: 0;
  }
}

.input-collapse-head {
  > .input-row {
    display: inline-flex;
    width: calc(100% - 30px);
  }
}

.cargo-info-collapsed-content {
  padding-left: 30px;
}

#outputtable {
  border: 0.3px solid #dddddd;
  border-spacing: 0;
  td {
    border: 0.3px solid #dddddd;
  }
}

.output-row {
  padding: 6px 0;
  display: flex;
  align-items: center;
}

.dragHandle {
  position: absolute;
  padding: 7px 0;
  top: 0;
  left: 0;
  cursor: move;
  .material-icons {
    font-size: 18px;
  }
}

.collapsible {
  display: flex;
  align-items: center;
}

.collapsible-caret {
  border: 0;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  font: inherit;
  padding: 0;
  margin-left: -5px;
  .material-icons {
    padding: 0;
  }
}

.collapsible-content {
  padding-left: 20px;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.modal-contents {
  background-color: #ffffff;
  padding: 20px 40px;
  border-radius: 8px;
  min-width: 360px;
}

.modal-heading {
  margin: 0 0 20px;
}

.delete-plan-btn {
  display: inline-flex;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 4px 0;
  margin: 10px 12px 0 0;
  align-items: center;
  .material-icons {
    font-size: 18px;
  }
}

.org-user-dp {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}


.table {
  width: 100%;
  th,
  td {
    text-align: left;
    padding: 10px;
  }
  th {
    font-size: 0.8rem;
    border-bottom: 2px solid #dddddd;
  }
  td {
    font-size: 0.7rem;
    border-bottom: 1px solid #dddddd;
  }
}

.btn-s {
  background-color: transparent;
  border: 0;
  color: #4b5060;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: white;
    color: #295efa;
    &.danger {
      color: crimson;
    }
  }
}

.plan-settings {
  position: fixed;
  top: 10px;
  left: 200px;
  display: flex;
}

.planslist-flex {
  flex-wrap: wrap;
}

.home-menu-btn {
  display: inline-block;
  cursor: pointer;
  width: 320px;
  height: 100px;
  font-size: 20px;
  font-weight: bold;
  border: 0;
  text-align: left;
  background-color: #106FCE;
  color: #fff;
  padding: 0 20px;
  span {
    span {
      display: inline-block;
      font-size: 12px;
    }
  }
}

.home-menu-back-btn {
  display: inline-block;
  cursor: pointer;
  width: 100px;
  height: 30px;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #106FCE;
  text-align: center;
  background-color: #ffffff;
  color: #106FCE;
  padding: 0 20px;
  span {
    span {
      display: inline-block;
      font-size: 12px;
    }
  }
}
.exec-btn {
  display: inline-block;
  cursor: pointer;
  width: 100px;
  height: 30px;
  font-size: 18px;
  font-weight: bold;
  border: 0;
  text-align: center;
  background-color: #f8a369;
  color: #fff;
  padding: 0 20px;
  span {
    span {
      display: inline-block;
      font-size: 12px;
    }
  }
}

.home-menu-btn-gray {
  display: inline-block;
  cursor: pointer;
  width: 320px;
  height: 100px;
  font-size: 24px;
  font-weight: bold;
  border: 0;
  text-align: left;
  background-color: #888;
  color: #fff;
  padding: 0 20px;
  span {
    span {
      display: inline-block;
      font-size: 12px;
    }
  }
}

.home-menu-bar {
  font-size: 20px;
  background-color: #555;
  color: #fff;
  padding-left: 10px;
  height: 40px;
  line-height: 40px;
}

.subtitle-bar {
  font-size: 20px;
  background-color: #AAA;
  padding-left: 10px;
  height: 40px;
  line-height: 40px;
  margin-top: 25px;
  margin-bottom: 10px;
}

.asoko-nav-item-link {
  color: #fff;
  text-decoration: none;
}

.grid-user-container {
  padding: 0 20px;
  border: 1px solid #000;
  display: grid;
  font-size: 14px;
  grid-template-columns: 0.15fr 0.05fr 0.3fr 0.15fr 0.05fr 0.7fr;
}

.grid-item {
  font-size: 14px;
}

.grid-item-head {
  font-size: 14px;
  background-color: #106FCE;
  color: #fff;
  border: 1px solid;
}

.grid-new-container {
  padding: 0 20px;
  display: grid;
  grid-template-columns: 0.5fr 0.7fr 0.5fr 1fr
}

.table-result {
  border-collapse: collapse;
  table-layout: fixed;
  text-align: left;
  font-size: 14px;
}

.table-result th, .table-result td {
  border: 1px solid;
  padding: 1em;
}

.table-result thead th {
  background-color: #106FCE;
  color: #fff;
  border: 1px solid #000;
}

.grid-file-container {
  padding: 0 20px;
  border: 1px solid #000;
  display: grid;
  font-size: 14px;
  grid-template-columns: 0.3fr 0.05fr 1fr
}

.asoko2-table {
  width: 100%;
  font-size: 18px;
  th {
    border: 1px #fff solid;
    background-color: #106FCE;
    color: #fff;
    }
  td {
    font-size: 0.7rem;
    border-bottom: 1px solid #dddddd;
  }
  .circular-container {
    position: relative;
  }
}

input[type='file'] {
  display: none
}