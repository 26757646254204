//#login-background {
//  width: 100%;
//  height: 100vh;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//}

.login-form {
  display: flex;
  flex-direction: column;
  .input-wrapper {
    width: 100%;
  }
}

.login-error {
  color: red;
  font-size: 0.7rem;
  line-height: 1.8;
}

#login-create {
  font-size: 0.7rem;
  text-align: center;
  margin: 20px 0 0;
  a {
    color: #295efa;
  }
}

#login-background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url('../assets/texture_background.webp'),
    linear-gradient(65deg, #7526d8 0%, #e23b56 100%);
  background-size: cover;
  background-position: top right;
  background-blend-mode: multiply;
  //z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#login-canvas {
  position: absolute;
  top: 0;
  left: 0;
}

#login-container {
  width: 300px;
  margin: 0 20px;
  &.wide {
    width: 500px;
  }
}

#login-modal {
  width: 100%;
  padding: 30px;
  background-color: white;
  z-index: 1000;
  border-radius: 4px;
}

#login-captcha-text {
  color: #ffffff;
  font-size: 0.6rem;
  margin-top: 12px;
  opacity: 0.7;
  line-height: 1.8;
  a {
    color: inherit;
  }
  &:hover {
    opacity: 1;
  }
}

#login-logo {
  height: 30px;
  margin: 0 auto;
  display: block;
}

#login-heading {
  text-transform: uppercase;
  font-size: 0.8rem;
  text-align: center;
  margin-top: 40px;
}

.input-wrapper {
  padding: 10px 0;
  position: relative;
}

.slate-wrapper {
  padding: 5px 10px 10px;
  box-shadow: inset 0 0 1px 1px #dbdff3;
  border-radius: 4px;
  height: 300px;
  display: flex;
  flex-direction: column;
}

.slate-editor {
  font-size: 0.8rem;
  line-height: 0.7;
  //height: 300px;
}

.slate-image-loader {
  width: 320px;
  height: 200px;
  display: flex;
  background-color: #fafafa;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: none;
  cursor: initial;
  color: #888888;
}

.slate-overflow {
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.input-label {
  display: block;
  font: inherit;
  font-size: 0.7rem;
  margin-bottom: 6px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.input {
  display: block;
  width: 100%;
  max-width: 300px;
  font: inherit;
  font-size: 0.8rem;
  border: 0;
  height: 34px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: inset 0 0 1px 1px #dbdff3;
  padding: 0 10px;
  &.fill {
    background-color: #f4f4f4;
    box-shadow: inset 0 0 0 0;
  }
  &.textarea {
    min-height: 150px;
    padding: 10px;
  }
  &.small {
    height: 30px;
  }
  &::placeholder {
    font: inherit;
    //font-size: 0.7rem;
  }

  &.with-icon {
    padding-left: 32px;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  &[data-autocompleted] {
    background-color: transparent !important;
  }

  &.danger {
    color: crimson;
    box-shadow: inset 0 0 1px 1px crimson;
  }
}

.lg-switch {
  display: inline-flex;
  .material-icons {
    font-size: 18px;
  }
  &.small {
    .material-icons {
      font-size: 16px;
    }
  }
}

.search-input {
  background-image: url('../assets/icon_search.svg');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 10px 9px;
  width: 400px;
}

.login-submit {
  background-color: #295efa;
  color: #ffffff;
  border: 0;
  font: inherit;
  font-size: 0.7rem;
  padding: 8px 30px;
  border-radius: 4px;
  margin: 20px auto 0;
  text-transform: uppercase;
  display: block;
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.login-lang-wrapper {
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
}

.toggle-input {
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-size: 0.7rem;
  padding: 4px 2px;
  &.small {
    font-size: 0.6rem;
  }
}

.toggle-item {
  padding: 1px 10px;
  margin: 1px;
  background-color: transparent;
  line-height: 1.9;
  height: 28px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  color: #4b5060;
  &.small {
    height: 24px;
    padding: 1px 10px 0;
  }
  &.selected {
    color: #295efa;
    background-color: #f8f9fc;
  }
}
